const images = [
  [
    {
      src: require('../../assets/images/Gallery/1.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/2.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/3.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s5.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s6.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s7.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s8.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s9.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s10.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s11.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s12.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s13.jpg'),
    },
    {
      src: require('../../assets/images/Gallery/s14.jpg'),
    },
  ],
]

export default images
