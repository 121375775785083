import React from 'react'
import Fade from 'react-reveal/Fade'

const AboutUs = () => {
  return (
    <Fade left>
      <div className="container about-us-cont">
        <div className="text-cont">
          <span className="header">SHIELD Fire Protection for Key Assets</span>
          <span className="content">
            SHIELD Fire Protection Services, your one-stop solution for all your
            fire protection needs. Our company is proud to be certified by the
            National Fire Protection Association (NFPA), Fire and Security
            Association of India (FSAI), International Institute of Fire
            Engineering (IIFE) and ISO 9001:2018, ensuring that our services
            meet the highest standards in the industry.
          </span>
          <span className="content">
            We provide full range of fire fighting equipments. Our team of
            experts is highly trained and experienced, and we use only the
            latest technology and equipment to ensure that your property is
            protected against fire.
          </span>
          <span className="content">
            Our commitment to quality and customer satisfaction is evident in
            everything we do. We work closely with our clients to understand
            their specific fire protection needs and tailor our services to meet
            those needs. Whether you're looking to install a new fire protection
            system in your residential or commercial spaces, or need to maintain
            an existing one, we're here to help.
          </span>
          <span className="content">
            We are aware of the significance of fire safety and its contribution
            to the preservation of property and human life. We are committed to
            offering our clients the best fire prevention services possible
            since we take our obligations seriously.
          </span>
        </div>
      </div>

      <div className="container certificate-cont">
        <img src={require('../../assets/images/cer1.jpeg')} alt="" />
        <img src={require('../../assets/images/cer2.jpeg')} alt="" />
        <img src={require('../../assets/images/cer3.jpeg')} alt="" />
        <img src={require('../../assets/images/cer4.jpeg')} alt="" />
      </div>
    </Fade>
  )
}

export default AboutUs
