import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Header from './components/Header'
import 'animate.css/animate.min.css'
import SplashScreen from './components/SplashScreen'
import Home from './views/Home/Home'
import sample1 from './assets/shield_main.mp4'
import { TfiClose } from 'react-icons/tfi'
import Slide from 'react-reveal/Slide'
// import VideoPlayer from 'react-background-video-player'
import Video from './components/Video'

function App() {
  const myRef = React.createRef()
  const [loadSplashScreen, setLoadSplashScreen] = useState(true)
  const [scroll, setScroll] = useState(0)
  const [playVideo, setPlayVideo] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoadSplashScreen(false)
    }, 1500)
  }, [])

  const onScroll = () => {
    const scrollTop = myRef.current.scrollTop
    setScroll(scrollTop)
  }

  if (playVideo) {
    return (
      <Slide left>
        <div className="video-full-screen-cont">
          <div className="close-btn-cont">
            <TfiClose className="icon" onClick={() => setPlayVideo(false)} />
          </div>
          {/* <VideoPlayer
            className="video"
            src={sample1}
            autoPlay={true}
            muted={false}
          /> */}

          <Video src={sample1} />
        </div>
      </Slide>
    )
  }

  return (
    <div className="body--dark" style={{ height: '100%' }}>
      {loadSplashScreen ? (
        <SplashScreen />
      ) : (
        <BrowserRouter>
          <Header scrolledPosition={scroll} />
          <div className="app-routes" onScroll={onScroll} ref={myRef}>
            <Home setPlayVideo={setPlayVideo} />
          </div>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
