import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { Spiral as Hamburger } from 'hamburger-react'

export default function Header(props) {
  const { scrolledPosition } = props
  const [isNavVisible, setNavVisibility] = useState(false)
  const [isAcive, setIsActiveTab] = useState('')

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)')
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      // setIsSmallScreen(true)
    } else {
      // setIsSmallScreen(false)
    }
  }

  return (
    <header
      className={clsx(
        'Header',
        scrolledPosition > 300 && !isNavVisible
          ? 'Header-scrolled'
          : isNavVisible
          ? 'navOpened'
          : '',
      )}
    >
      <div className="Logo">
        <a href="#main" onClick={() => setNavVisibility(false)}>
          <img
            src={require('../assets/images/logoName.png')}
            alt="logo"
            className="img1"
          />
        </a>

        <div className="slogan">
          <img src={require('../assets/images/poweredBy.png')} alt="logo" />
        </div>
      </div>
      <CSSTransition
        in={isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <>
          {isNavVisible && (
            <nav className="Nav">
              <div className="nav-items-cont">
                <a
                  href="#about-us"
                  className={clsx(isAcive === 'about-us' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('about-us')
                    setNavVisibility(false)
                  }}
                >
                  About US
                </a>
                <a
                  href="#services"
                  className={clsx(isAcive === 'services' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('services')
                    setNavVisibility(false)
                  }}
                >
                  Services
                </a>
                <a
                  href="#gallery"
                  className={clsx(isAcive === 'gallery' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('gallery')
                    setNavVisibility(false)
                  }}
                >
                  Gallery
                </a>
                <a
                  href="#teams"
                  className={clsx(isAcive === 'teams' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('teams')
                    setNavVisibility(false)
                  }}
                >
                  Our Team
                </a>
                <a
                  href="#contact-us"
                  className={clsx(isAcive === 'contact-us' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('contact-us')
                    setNavVisibility(false)
                  }}
                >
                  Contact Us
                </a>
              </div>
            </nav>
          )}
        </>
      </CSSTransition>

      <Hamburger
        color="var(--text-primary)"
        onToggle={() => setNavVisibility(!isNavVisible)}
        distance="sm"
        size={50}
        rounded={false}
        barHeight={10}
        className="hamburger"
        toggled={isNavVisible}
      />
    </header>
  )
}
