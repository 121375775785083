import React from 'react'
import Slide from 'react-reveal/Slide'

const Services = () => {
  return (
    <div className="services-cont">
      <Slide right>
        <div className="container-main">
          <div className="left-cont">
            <div className="content-with-icons">
              <img
                src={require('../../assets/images/fire-extinguisher.png')}
                className="image-icon"
                alt=""
              />
              <div className="text-cont">
                <span className="header">Fire Extinguishers </span>
                <span className="content">
                  Our fire extinguishers are designed to quickly put out fires
                  and protect people and property. We offer a wide range of fire
                  extinguishers to meet the specific needs of different
                  environments
                </span>
                <span className="explorer"></span>
              </div>
            </div>

            <div className="content-with-icons">
              <img
                src={require('../../assets/images/fire-hydrant.png')}
                className="image-icon"
                alt=""
              />
              <div className="text-cont">
                <span className="header">Fire Hydrants </span>
                <span className="content">
                  Fire hydrants are an essential component of any fire
                  protection system. Our fire hydrants are designed to provide
                  an ample supply of water to the fire department in case of an
                  emergency.
                </span>
                <span className="explorer"></span>
              </div>
            </div>
          </div>

          <div className="right-cont">
            <img src={require('../../assets/images/firefighters.jpg')} alt="" />
          </div>
        </div>
      </Slide>
      <Slide left>
        <div className="container-main">
          <div className="left-cont secondary-left-cont">
            <div className="content-with-icons">
              <img
                src={require('../../assets/images/alarm.png')}
                className="image-icon"
                alt=""
              />
              <div className="text-cont">
                <span className="header">Fire Alarm System </span>
                <span className="content">
                  A fire alarm system is crucial in alerting people to evacuate
                  a building in case of a fire. Our fire alarm systems are
                  designed to detect and alert occupants quickly in case of a
                  fire.
                </span>
                <span className="explorer"></span>
              </div>
            </div>

            <div className="content-with-icons">
              <img
                src={require('../../assets/images/sprinkler-fire.png')}
                className="image-icon"
                alt=""
              />
              <div className="text-cont">
                <span className="header">Fire Sprinkler Systems </span>
                <span className="content">
                  Our fire sprinkler systems are designed to automatically
                  detect and control fires. These systems are essential for
                  protecting property and minimizing fire damage.
                </span>
                <span className="explorer"></span>
              </div>
            </div>

            <div className="content-with-icons">
              <img
                src={require('../../assets/images/safety-doors.png')}
                className="image-icon"
                alt=""
              />
              <div className="text-cont">
                <span className="header">Fire Safety Doors </span>
                <span className="content">
                  - Fire safety doors are designed to prevent the spread of fire
                  and smoke from one room to another. Our fire safety doors are
                  made from high-quality materials and are tested to meet
                  industry standards.
                </span>
                <span className="explorer"></span>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default Services
