import ReactCarousel, { AFTER, CENTER, BEFORE } from 'react-carousel-animated'
import images from './images'
import 'react-carousel-animated/dist/style.css'

function Gallery() {
  return (
    <div className="gallery-cont">
      <ReactCarousel
        carouselConfig={{
          transform: {
            rotateY: {
              [BEFORE]: () => 'rotateY(25deg)',
              [CENTER]: () => 'rotateY(0deg)',
              [AFTER]: () => 'rotateY(-25deg)',
            },
          },
        }}
        itemBackgroundStyle={{
          backgroundColor: '#ece4db',
          borderRadius: '3px',
          boxShadow: '8px 12px 14px -6px black',
        }}
        containerBackgroundStyle={{
          filter: 'blur(7px)',
          backgroundColor: 'rgba(62, 212, 214, 0.3)',
        }}
        itemMaxWidth={50}
        carouselHeight="350px"
      >
        {images[0].map((image, index) => (
          <img key={index} src={image.src} alt="test" />
        ))}
      </ReactCarousel>
    </div>
  )
}

export default Gallery
