import React, { useRef } from 'react'
import Footer from '../../components/Footer'
import AboutUs from './AboutUs'
import Banner from './Banner'
import Gallery from './Gallery'
import Services from './Services'

const Home = (props) => {
  const { setPlayVideo, playVideo } = props
  const contentRef = useRef()

  function handleBackClick() {
    contentRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <div className="home-cont" id="main">
      <Banner
        setPlayVideo={setPlayVideo}
        playVideo={playVideo}
        handleBackClick={handleBackClick}
      />
      {!playVideo && (
        <div className="home-contents-cont" ref={contentRef}>
          <div id="about-us" />
          <AboutUs />
          <div id="services" style={{ height: '15rem' }} />
          <Services />
          <div id="gallery" />
          <Gallery />
        </div>
      )}

      {!playVideo && <Footer />}
    </div>
  )
}

export default Home
