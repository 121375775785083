import React from 'react'
import sample from '../../assets/shield_trimmed.mp4'
import { BsPlayCircle } from 'react-icons/bs'
import VideoPlayer from 'react-background-video-player'
import { FaAngleDoubleDown } from 'react-icons/fa'

const Banner = (props) => {
  const { setPlayVideo, handleBackClick } = props

  return (
    <div className="banner-cont">
      <VideoPlayer
        className="video"
        src={sample}
        autoPlay={true}
        muted={true}
      />
      <div className="content-overlay">
        <div className="content-box">
          <span className="text">
            WE SERVE TO <span className="items-highlighter">SAVE</span>{' '}
          </span>
          <div className="button-cont" onClick={() => setPlayVideo(true)}>
            <BsPlayCircle className="icon" />
            Watch full film
          </div>
        </div>

        <div onClick={handleBackClick} className="scroller-cont">
          <div className="scroll-animate-view">
            <div className="content">
              <span className="cont-1">Scroll down</span>
              <span className="cont-2">to discover more</span>
            </div>
            <FaAngleDoubleDown className="icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
