import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="footer-cont" id="contact-us">
      <div className="first-cont">
        <div className="copyright-cont">
          <span className="copyright">
            © {new Date().getFullYear()} by Shield. All Rights Reserved.
          </span>
          <span className="cookies">Privacy policy and cookies</span>
        </div>

        <div className="link-cont">
          <span className="header">Services</span>
          <span className="link">Fire Extinguishers</span>
          <span className="link">Fire Hydrants</span>
          <span className="link">Fire Alarm System</span>
          <span className="link">Fire Sprinkler Systems</span>
          <span className="link">Fire Safety Doors</span>
        </div>

        <div className="copyright-cont">
          <span className="copyright">Get in touch</span>
          <span className="cookies">
            3369/10, 13th Main Rd, MCC B Block, Kuvempu Nagar, Davanagere,
            Karnataka 577004.
          </span>
          <span className="cookies">
            73535 30000 | 98864 70404 | 73535 32666
          </span>
        </div>
      </div>

      <div className="second-cont">
        <div className="follow-us-cont">
          <span className="header">Follow Us </span>
          <HiOutlineArrowNarrowRight className="icon" />
          <span className="link">Facebook</span>
          <span className="link">Instagram</span>
          <span className="link">YouTube</span>
        </div>

        <div className="designed-by">
          Design made by{" "}
          <img
            src={require("../assets/images/hero.png")}
            alt=""
            style={{ height: "4rem", paddingLeft: "1rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
